<template>
  <div class="content" style="min-height:100vh">
    <navbar @asideShow="asideFlagFun" ></navbar>
    <div class="main">
      <div class="title">
        <div @click="back">
          <van-image width="9px" height="15px" :src="require('@/assets/cls/arrow-l.png')" />
          <span>{{$t("activeRecord.return")}}</span>
        </div>
      </div>
      <div class="btn">
        <div :class="1==i?'current':''" @click="screenHandle('USDT',1)">USDT</div>
        <div  :class="2==i?'current':''"  @click="screenHandle('XX',2)">UNA</div>
        <div  :class="3==i?'current':''"  @click="screenHandle('XX',3)">BNB</div>
      </div>
      <div class="record" v-if="type == 1">
        <div class="record_top">
          <div>{{$t("activeRecord.time")}}</div>
          <div>{{$t("activeRecord.user")}}ID</div>
          <div>{{$t("activeRecord.ReceivedQuantity")}}($)</div>
        </div>
        <van-pull-refresh style="height:561px;overflow: scroll;" :success-text="$t('activeRecord.refresh')" v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('activeRecord.NoMore')"
            @load="onLoad"
            class="list"
            :immediate-check="false"
            :offset="100"
          >
            <div class="record_center" v-for="(item,index) in USDT_list" :key="index">
              <div>{{item.created_at}}</div>
              <div>{{item.userid}}</div>
              <div>{{item.amount}}</div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
      <div class="record" v-if="type == 2">
            <div class="record_top">
              <div>{{$t("activeRecord.time")}}</div>
              <div>{{$t("activeRecord.user")}}ID</div>
              <div>{{$t("activeRecord.ReceivedQuantity")}}($)</div>
            </div>
            <van-pull-refresh style="height:561px;overflow: scroll;" :success-text="$t('activeRecord.refresh')" v-model="refreshing" @refresh="onRefresh">
              <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="$t('activeRecord.NoMore')"
                @load="onLoad"
                class="list"
                :immediate-check="false"
                :offset="100"
              >
              <div class="record_center" v-for="(item,index) in UNA_list" :key="index">
                  <div>{{item.created_at}}</div>
                  <div>{{item.userid}}</div>
                  <div>{{item.amount}}</div>
              </div>
          </van-list>
        </van-pull-refresh>
      </div>
      <div class="record" v-if="type == 3">
        <div class="record_top">
          <div>{{$t("activeRecord.time")}}</div>
          <div>{{$t("activeRecord.user")}}ID</div>
          <div>{{$t("activeRecord.ReceivedQuantity")}}($)</div>
        </div>
        <van-pull-refresh style="height:561px;overflow: scroll;" :success-text="$t('activeRecord.refresh')" v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('activeRecord.NoMore')"
            @load="onLoad"
            class="list"
            :immediate-check="false"
            :offset="100"
          >
            <div class="record_center" v-for="(item,index) in BNB_list" :key="index">
              <div>{{item.created_at}}</div>
              <div>{{item.userid}}</div>
              <div>{{item.amount}}</div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <!-- <van-popup class="screen-popup" v-model="popupShow" position="bottom" round>
      <div class="title">{{$t('activeRecord.screening')}}</div>
      <div class="condition">
        <div
          v-for="(item, i) in screenList"
          :class="i==currentScreen?'currentScreen':''"
          :key="item"
          @click="screenHandle(i)"
        >{{item}}</div>
      </div>
      <div class="btn">
        <van-button plain type="info" color="#00A8FF" @click="popupShow = false">{{$t('activeRecord.cancel')}}</van-button>
        <van-button color="#00A8FF" @click="sub">{{$t('activeRecord.submit')}}</van-button>
      </div>
    </van-popup> -->
    
    <asidePopup v-if="asideFlag" @close="noShow" :shows='asideFlag'></asidePopup>

  </div>
</template>
<script>
import asidePopup from "@/components/common/asidePopup";

export default {
  components: { asidePopup },

  data() {
    return {
      asideFlag: false,
      popupShow: false,
      screenList: [this.$t('activeRecord.all'),this.$t('activeRecord.pledge'), "NFT",this.$t('activeRecord.node'), "CLS",this.$t('activeRecord.income'),this.$t('activeRecord.TopUp')],
      currentScreen: 1,
      page: 1,
      list: [
        {w_type:1,created_at:2,msg:21},
        {w_type:1,created_at:2,msg:21},
        {w_type:1,created_at:2,msg:21},

      ],
      USDT_list:[],
      UNA_list:[],
      BNB_list:[],
      i:1,
      loading: false, //加载
      finished: false, //加载结束
      refreshing: false, //下拉刷新
      page:1,
      type:1,
    };
  },
  methods: {
    getactiveInfo(show){
      this.$axios.get(this.$api.withdraw_record,
        {
          type:this.type,
          page:this.page,
          pageSize:10
        }
      ).then(res => {
          if(this.type == 1){
            if(show){
              this.USDT_list =[...this.USDT_list,...res.data.list] 
              this.loading = false;
              if(!res.data.list.length){
                this.finished = true
              }
            }else{
              this.USDT_list = res.data.list
              this.refreshing = false
            }
          }else if(this.type == 2) {
            if(show){
              this.UNA_list =[...this.UNA_list,...res.data.list] 
              this.loading = false;
              if(!res.data.list.length){
                this.finished = true
              }
            }else{
              this.UNA_list = res.data.list
              this.refreshing = false
            }
          } else {
            if(show){
              this.BNB_list =[...this.BNB_list,...res.data.list] 
              this.loading = false;
              if(!res.data.list.length){
                this.finished = true
              }
            }else{
              this.BNB_list = res.data.list
              this.refreshing = false
            }
          }
        })
    },
    noShow(){
      this.asideFlag = false
    },
    asideFlagFun(){
      this.asideFlag = true
    },
    back(){
      this.$router.back()
    },
    asideFlagShow(e) {
      this.asideFlag = e;
    },
    screenHandle(i,num) {
      this.currentScreen = num;
      this.i = num
      this.type = num
      this.page = 1
      this.getactiveInfo()
    },
    sub() {
      this.popupShow = false;
      // this.getList();
    },
    //上拉加载事件
    onLoad() {
      let show = true
      this.page++; //每次加载页数加1
      this.getactiveInfo(show)
    },
    // 下拉刷新
    onRefresh() {
      this.finished = false; // 不写这句会导致你上拉到底过后在下拉刷新将不能触发下拉加载事件
      this.page = 1;
      this.getactiveInfo()
    },
  },
  created() {
    this.$store.dispatch("getSetting"); //网站配置
    this.getactiveInfo()
    if(this.$route.query.type){
      this.currentScreen = this.$route.query.type
    }
    // this.getList();
  }
};
</script>
<style lang="scss" scoped>
.main {
  padding: 13px 15px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    > div:first-child {
      display: flex;
      align-items: center;
      span {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        margin-left: 6px;
      }
    }
  }
  .list {
    .item {
      background: #f3f9ff;
      border-radius: 10px;
      padding: 15px;
      margin-bottom: 5px;
      .name {
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        .line {
          width: 3px;
          height: 10px;
          background: #3a8dff;
          border-radius: 2px;
        }
        > div:last-child {
          margin-left: 5px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
      }
      .time {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 10px;
      }
      .msg {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #3a8dff;
        margin-bottom: 20px;
      }
    }
  }
}
.screen-popup {
  padding: 21px 15px;
  .title {
    text-align: center;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-bottom: 33px;
  }
  .condition {
    display: flex;
    margin-bottom: 28px;
    flex-wrap: wrap;
    .currentScreen {
      color: #ffffff;
      background: #00a8ff;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24%;
      margin-right: 1.3%;
      font-size: 10px;
      font-family: Source Han Sans CN;
      margin-bottom: 10px;
      height: 30px;
      background: #f2f2f2;
      border-radius: 5px;
      font-weight: 400;
      color: #333333;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    button {
      width: 49%;
      &:first-child {
        margin-right: 2%;
      }
    }
  }
}
.btn {
  height: 40px;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 0 27px 40px;
  display: flex;
  div {
    width: 50%;
    height: 100%;
    color: #000;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    
    align-items: center;
  }
  .current {
    background: #8f92fa;
    color: #ffffff;
  }
}
  .list{
    .cell-title{
        font-size: 15px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #333333;

    }
    /deep/.van-cell::after{

border-bottom: 1px solid #EAEAEA;
}
  }
  .record {
    width: 100%;
    height: 621px;
    border: 1px solid #000;
    border-radius: 15px;
    margin-top: 12px;
    padding: 10px 5px;
    .record_top {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      div{
        //   width: 94px;
        flex: 0.3;
          text-align: left;
      }
      div:nth-of-type(2){
          text-align: center;
      }
      div:nth-of-type(3){
          text-align: right;
      }
    }
    .record_center {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000;
      div{
        //   width: 94px;
        flex: 0.3;
          text-align: left;
      }
      div:nth-of-type(2){
          text-align: center;
      }
      div:nth-of-type(3){
          text-align: right;
      }
    }
  }
</style>